import revive_payload_client_SyXc8LjQJq from "/srv/app/node_modules/.pnpm/nuxt@3.12.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.14.9_encoding@0.1.1_vdg6txsdwb4khqclarqo44zcoq/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_qji9qyupMt from "/srv/app/node_modules/.pnpm/nuxt@3.12.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.14.9_encoding@0.1.1_vdg6txsdwb4khqclarqo44zcoq/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_lyqtCza27r from "/srv/app/node_modules/.pnpm/nuxt@3.12.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.14.9_encoding@0.1.1_vdg6txsdwb4khqclarqo44zcoq/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_ditZfDWvSv from "/srv/app/node_modules/.pnpm/nuxt-site-config@2.2.12_@nuxt+devtools@1.3.7_rollup@4.18.0_vite@5.3.1_@types+node@20.14.9_sas_drlyq7j2cssqg2rg7aohxkpqqq/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.mjs";
import payload_client_GeRi70zOSf from "/srv/app/node_modules/.pnpm/nuxt@3.12.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.14.9_encoding@0.1.1_vdg6txsdwb4khqclarqo44zcoq/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_IDMgx7TKXY from "/srv/app/node_modules/.pnpm/nuxt@3.12.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.14.9_encoding@0.1.1_vdg6txsdwb4khqclarqo44zcoq/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_3fNHo63c2q from "/srv/app/node_modules/.pnpm/@pinia+nuxt@0.5.1_magicast@0.3.4_rollup@4.18.0_typescript@5.5.2_vue@3.4.31_typescript@5.5.2_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/srv/app/src/.nuxt/components.plugin.mjs";
import prefetch_client_S91upz8fyX from "/srv/app/node_modules/.pnpm/nuxt@3.12.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.14.9_encoding@0.1.1_vdg6txsdwb4khqclarqo44zcoq/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_iZ2dspdIS7 from "/srv/app/node_modules/.pnpm/nuxt-gtag@2.0.6_magicast@0.3.4_rollup@4.18.0/node_modules/nuxt-gtag/dist/runtime/plugin.client.mjs";
import pwa_icons_PyGy2sHE1W from "/srv/app/node_modules/.pnpm/@vite-pwa+nuxt@0.8.1_magicast@0.3.4_rollup@4.18.0_vite@5.3.1_@types+node@20.14.9_sass@1.77.6__azxfnpxho3mldugg2ypxwbya7u/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa-icons.mjs";
import pwa_client_nGLGKHXXjh from "/srv/app/node_modules/.pnpm/@vite-pwa+nuxt@0.8.1_magicast@0.3.4_rollup@4.18.0_vite@5.3.1_@types+node@20.14.9_sass@1.77.6__azxfnpxho3mldugg2ypxwbya7u/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.mjs";
import titles_DVGkDkLL8G from "/srv/app/node_modules/.pnpm/@nuxtjs+seo@2.0.0-rc.11_@lezer+common@1.2.1_@nuxt+devtools@1.3.7_rollup@4.18.0_vite@5.3.1_@ty_aoufsfwwyix24m4lc6nb366hoy/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/titles.js";
import defaults_TupaiIFDJn from "/srv/app/node_modules/.pnpm/@nuxtjs+seo@2.0.0-rc.11_@lezer+common@1.2.1_@nuxt+devtools@1.3.7_rollup@4.18.0_vite@5.3.1_@ty_aoufsfwwyix24m4lc6nb366hoy/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/defaults.js";
import siteConfig_fznM09c22b from "/srv/app/node_modules/.pnpm/nuxt-seo-experiments@4.0.0-rc.9_@nuxt+devtools@1.3.7_rollup@4.18.0_vite@5.3.1_@types+node@20._tdcmprxfzwqvwptpg326p7isj4/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/siteConfig.js";
import inferSeoMetaPlugin_WHp9jSXlkf from "/srv/app/node_modules/.pnpm/nuxt-seo-experiments@4.0.0-rc.9_@nuxt+devtools@1.3.7_rollup@4.18.0_vite@5.3.1_@types+node@20._tdcmprxfzwqvwptpg326p7isj4/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/inferSeoMetaPlugin.js";
import i18n_CJnJACZrwu from "/srv/app/node_modules/.pnpm/@nuxtjs+i18n@8.3.0_magicast@0.3.4_rollup@4.18.0_vue@3.4.31_typescript@5.5.2_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import slideovers_nZvfLMkfZN from "/srv/app/node_modules/.pnpm/@nuxt+ui@2.17.0_change-case@4.1.2_focus-trap@7.5.4_magicast@0.3.4_rollup@4.18.0_vite@5.3.1_@t_36ryt3d5r6ehbjmihb4dev2zoy/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.mjs";
import modals_1K5JuFOy3M from "/srv/app/node_modules/.pnpm/@nuxt+ui@2.17.0_change-case@4.1.2_focus-trap@7.5.4_magicast@0.3.4_rollup@4.18.0_vite@5.3.1_@t_36ryt3d5r6ehbjmihb4dev2zoy/node_modules/@nuxt/ui/dist/runtime/plugins/modals.mjs";
import colors_ztIFjsR3Ea from "/srv/app/node_modules/.pnpm/@nuxt+ui@2.17.0_change-case@4.1.2_focus-trap@7.5.4_magicast@0.3.4_rollup@4.18.0_vite@5.3.1_@t_36ryt3d5r6ehbjmihb4dev2zoy/node_modules/@nuxt/ui/dist/runtime/plugins/colors.mjs";
import plugin_client_A5JI51M4hu from "/srv/app/node_modules/.pnpm/@nuxtjs+color-mode@3.4.2_magicast@0.3.4_rollup@4.18.0/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_YbQylsqY4E from "/srv/app/node_modules/.pnpm/@dargmuesli+nuxt-cookie-control@8.4.2_magicast@0.3.4_rollup@4.18.0_webpack@5.92.1_esbuild@0.21.5_/node_modules/@dargmuesli/nuxt-cookie-control/dist/runtime/plugin.js";
import chunk_reload_client_TKKzJDto0p from "/srv/app/node_modules/.pnpm/nuxt@3.12.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.14.9_encoding@0.1.1_vdg6txsdwb4khqclarqo44zcoq/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import dayjs_pGiXRjcsJO from "/srv/app/src/plugins/dayjs.ts";
import i18n_VfGcjrvSkj from "/srv/app/src/plugins/i18n.ts";
import sentry_client_shVUlIjFLk from "/srv/app/src/plugins/sentry.client.ts";
import urql_FlK1jaV38U from "/srv/app/src/plugins/urql.ts";
export default [
  revive_payload_client_SyXc8LjQJq,
  unhead_qji9qyupMt,
  router_lyqtCza27r,
  _0_siteConfig_ditZfDWvSv,
  payload_client_GeRi70zOSf,
  check_outdated_build_client_IDMgx7TKXY,
  plugin_vue3_3fNHo63c2q,
  components_plugin_KR1HBZs4kY,
  prefetch_client_S91upz8fyX,
  plugin_client_iZ2dspdIS7,
  pwa_icons_PyGy2sHE1W,
  pwa_client_nGLGKHXXjh,
  titles_DVGkDkLL8G,
  defaults_TupaiIFDJn,
  siteConfig_fznM09c22b,
  inferSeoMetaPlugin_WHp9jSXlkf,
  i18n_CJnJACZrwu,
  slideovers_nZvfLMkfZN,
  modals_1K5JuFOy3M,
  colors_ztIFjsR3Ea,
  plugin_client_A5JI51M4hu,
  plugin_YbQylsqY4E,
  chunk_reload_client_TKKzJDto0p,
  dayjs_pGiXRjcsJO,
  i18n_VfGcjrvSkj,
  sentry_client_shVUlIjFLk,
  urql_FlK1jaV38U
]